$default-color: #d23c1c;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs: 480px !default;
$screen-sm: 767px !default;
$screen-md: 1024px !default;
$screen-lg: 1199px !default;
$screen-xlg: 1399px !default;

$white: #fff;
$color1: #273343;
$color2: #374E5C;
$color3: #889BA2;
$color4: #F5F5F5;
$color5: #D9E7EB;


$colour1: #C9EEFC;
$colour2: #373D87;
$colour3: #ECECEC;
$colour4: #FF5C26;
$colour5: #11132B;

$colour6:#E14938;
$colour7:#EB6B56;
$colour8:#FBA026;
$colour9:#C48367;
$colour10:#A38F84;
$colour11:#75706B;
$colour12:#666666;
$colour13:#9F9F9F;



$calendar1:#1A425E;
$calendar2:#ccc;
$calendar3:#f7f7f7;
$calendar4:#f0f0f0;
$calendar5:#c6d3dc;
$calendar6:#efefef;
$calendar7:#fbfbfb;
$calendar8:#E14938;
$calendar9:#89B404;
$calendar10:#FBA026;
$calendar11:#00A1E5;

